import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default function Projects({ data }) {
  console.log(data)
  return (
    <Layout>

      <div>
        <p>Coming soon.</p>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx {
      totalCount
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
          excerpt
          id
        }
      }
    }
  }
`